@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  @apply min-h-screen;
}

/* Target any focused element under body.body--a11y
   except for elements with a parent that is using the a11y-focus-within class,
   and apply the focus-ring utility class to them */
body.body--a11y
  *:not(.a11y-focus-within\:focus-ring *, .a11y-focus\:focus-ring):focus {
  @apply focus-ring;
}

/* Overrides the inline display property that is applied by the normalization */
.adyen-checkout__image,
.adyen-checkout__icon {
  @apply block;
}

@layer base {
  /* Font faces */
  /* AMK */

  @font-face {
    font-family: "Visuelt Pro";
    src: local("Visuelt Pro"),
      url("https://ggfrontendassets.azureedge.net/fonts/adamsmatkasse/Visuelt/VisueltPro-Regular.woff")
        format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Visuelt Pro";
    src: local("Visuelt Pro"),
      url("https://ggfrontendassets.azureedge.net/fonts/adamsmatkasse/Visuelt/VisueltPro-Regular.woff2")
        format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Visuelt Pro";
    src: local("Visuelt Pro"),
      url("https://ggfrontendassets.azureedge.net/fonts/adamsmatkasse/Visuelt/VisueltPro-Medium.woff")
        format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Visuelt Pro";
    src: local("Visuelt Pro"),
      url("https://ggfrontendassets.azureedge.net/fonts/adamsmatkasse/Visuelt/VisueltPro-Medium.woff2")
        format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Ivar Headline";
    src: local("Ivar Headline"),
      url("https://ggfrontendassets.azureedge.net/fonts/adamsmatkasse/Ivar/IvarHeadline-Regular.woff")
        format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Ivar Headline";
    src: local("Ivar Headline"),
      url("https://ggfrontendassets.azureedge.net/fonts/adamsmatkasse/Ivar/IvarHeadline-Regular.woff2")
        format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Ivar Headline";
    src: local("Ivar Headline"),
      url("https://ggfrontendassets.azureedge.net/fonts/adamsmatkasse/Ivar/IvarHeadline-SemiBold.woff")
        format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Ivar Headline";
    src: local("Ivar Headline"),
      url("https://ggfrontendassets.azureedge.net/fonts/adamsmatkasse/Ivar/IvarHeadline-SemiBold.woff2")
        format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  /* LMK */
  @font-face {
    font-family: "Korolev";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("https://ggfrontendassets.azureedge.net/fonts/linasmatkasse/Korolev/Korolev-Medium.woff")
      format("woff");
  }
  @font-face {
    font-family: "Korolev";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("https://ggfrontendassets.azureedge.net/fonts/linasmatkasse/Korolev/Korolev-Bold.woff")
      format("woff");
  }

  @font-face {
    font-family: "Recoleta";
    font-display: swap;
    unicode-range: U+000-5FF;
    src: url("https://ggfrontendassets.azureedge.net/fonts/linasmatkasse/Recoleta/Recoleta-SemiBold.woff")
      format("woff");
    font-style: normal;
    font-weight: 600 700;
  }

  /* GL */
  @font-face {
    font-family: "Brown";
    src: local("Brown"),
      url("https://ggfrontendassets.azureedge.net/fonts/godtlevert/lineto-brown-regular.woff2")
        format("woff2"),
      url("https://ggfrontendassets.azureedge.net/fonts/godtlevert/lineto-brown-regular.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Brown";
    src: local("Brown"),
      url("https://ggfrontendassets.azureedge.net/fonts/godtlevert/lineto-brown-italic.woff2")
        format("woff2"),
      url("https://ggfrontendassets.azureedge.net/fonts/godtlevert/lineto-brown-italic.woff")
        format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Brown";
    src: local("Brown"),
      url("https://ggfrontendassets.azureedge.net/fonts/godtlevert/lineto-brown-bold.woff2")
        format("woff2"),
      url("https://ggfrontendassets.azureedge.net/fonts/godtlevert/lineto-brown-bold.woff")
        format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Brown";
    src: local("Brown"),
      url("https://ggfrontendassets.azureedge.net/fonts/godtlevert/lineto-brown-bolditalic.woff2")
        format("woff2"),
      url("https://ggfrontendassets.azureedge.net/fonts/godtlevert/lineto-brown-boldItalic.woff")
        format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  /* RN */
  @font-face {
    font-family: "Museo Sans Rounded";
    font-style: normal;
    font-weight: 300;
    src: url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_0_0.eot");
    src: url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_0_0.eot?#iefix")
        format("embedded-opentype"),
      url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_0_0.woff2")
        format("woff2"),
      url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_0_0.woff")
        format("woff"),
      url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_0_0.ttf")
        format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: "Museo Sans Rounded";
    font-style: normal;
    font-weight: 900;
    src: url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_1_0.eot");
    src: url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_1_0.eot?#iefix")
        format("embedded-opentype"),
      url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_1_0.woff2")
        format("woff2"),
      url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_1_0.woff")
        format("woff"),
      url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_1_0.ttf")
        format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: "Museo Sans Rounded";
    font-style: normal;
    font-weight: 500;
    src: url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_2_0.eot");
    src: url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_2_0.eot?#iefix")
        format("embedded-opentype"),
      url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_2_0.woff2")
        format("woff2"),
      url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_2_0.woff")
        format("woff"),
      url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_2_0.ttf")
        format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: "Museo Sans Rounded";
    font-style: normal;
    font-weight: 700;
    src: url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_3_0.eot");
    src: url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_3_0.eot?#iefix")
        format("embedded-opentype"),
      url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_3_0.woff2")
        format("woff2"),
      url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_3_0.woff")
        format("woff"),
      url("https://ggfrontendassets.azureedge.net/fonts/retnemt/36BC3D_3_0.ttf")
        format("truetype");
    font-display: swap;
  }

  /* No body margin */
  body {
    @apply m-0;
  }

  /* Override tailwind base by reverting to browser default */
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: inline;
    vertical-align: middle;
  }

  /* Remove focus outline and font ligatures */
  *,
  button {
    @apply focus:outline-none no-ligatures focus:outline-offset-0;
  }

  /* Prevent scroll when frozen */
  body.body--frozen {
    @apply overflow-hidden;
  }

  /* Apply top margin to intercom to avoid overlapping status bar on mobile view  */
  @media (max-width: 450px) {
    .intercom-webview-fix iframe[name="intercom-messenger-frame"] {
      margin-top: 48px !important;
      height: calc(100vh - 48px) !important;
    }

    /* Apply bottom margin to intercom launcher bubble to prevent menu at bottom overlap */
    .intercom-bubble-bottom-margin .intercom-lightweight-app-launcher {
      margin-bottom: 72px !important;
    }
    .browser-intercom-bubble-bottom-margin .intercom-lightweight-app-launcher {
      margin-bottom: 54px !important;
    }
  }

  /* prettier-ignore */
  .th-amk {
    --primary: 0, 13, 113;              /* #000d71 */
    --secondary: 102, 125, 78;          /* #667d4e */
    --black: 29, 28, 26;                /* #1d1c1a */
    --white: 255, 255, 255;             /* #ffffff */
    --grey1: 137, 137, 137;             /* #898989 */
    --grey2: 233, 229, 226;             /* #e9e5e2 */
    --grey3: 244, 244, 241;             /* #f4f4f1 */
    --background: 250, 250, 249;        /* #fafaf9 */
    --discount: 193, 104, 63;           /* #c1683f */
    --information: 102, 125, 78;        /* #667d4e */
    --error: 218, 56, 35;               /* #da3823 */
    --highlight: 255, 203, 46;          /* #ffcb2e */
    --hover: 1, 11, 91;                 /* #010b5b */
    --disabled: 204, 207, 227;          /* #cccfe3 */
    --informationBG: 240, 242, 237;     /* #f0f2ed */
    --errorBG: 253, 245, 244;           /* #fdf5f4 */
    --highlightBG: 255, 252, 245;       /* #fffcf5 */
    --font: "Visuelt Pro";
    --font-alternative: "Ivar Headline";
  }

  /* AMK overrides */
  .th-amk {
    @apply font-normal;
    @apply font-sans;
  }

  .th-amk strong {
    @apply font-medium;
  }

  .th-amk h1,
  .th-amk h2,
  .th-amk h3 {
    @apply font-serif font-normal;
  }

  .th-amk h1 strong,
  .th-amk h2 strong,
  .th-amk h3 strong,
  .th-amk strong h1,
  .th-amk strong h2,
  .th-amk strong h3 {
    @apply font-serif font-semibold;
  }

  /* prettier-ignore */
  .th-lmk {
    --primary: 97, 75, 121;             /* #614b79 */
    --secondary: 40, 92, 77;            /* #285c4d */
    --black: 29, 28, 26;                /* #1d1c1a */
    --white: 255, 255, 255;             /* #ffffff */
    --grey1: 137, 137, 137;             /* #898989 */
    --grey2: 244, 229, 206;             /* #f4e5ce */
    --grey3: 255, 246, 232;             /* #fff6e8 */
    --background: 255, 253, 250;        /* #fffdfa */
    --discount: 255, 84, 67;            /* #ff5443 */
    --information: 40, 92, 77;          /* #285c4d */
    --error: 174, 23, 45;               /* #ae172d */
    --highlight: 255, 203, 46;          /* #ffcb2e */
    --hover: 85, 53, 120;               /* #553578 */
    --disabled: 223, 219, 228;          /* #dfdbe4 */
    --informationBG: 234, 239, 237;     /* #eaefed */
    --errorBG: 251, 243, 244;           /* #fbf3f4 */
    --highlightBG: 255, 252, 245;       /* #fffcf5 */
    --font: "Korolev", sans-serif;
    --font-alternative: "Recoleta";
  }

  /* LMK overrides */
  .th-lmk {
    @apply font-medium;
    @apply font-sans;
  }

  .th-lmk strong {
    @apply font-bold;
  }

  .th-lmk h1 {
    @apply text-error;
  }

  .th-lmk h1,
  .th-lmk h2,
  .th-lmk h3 {
    @apply font-serif font-semibold;
  }

  .th-lmk h1 strong,
  .th-lmk h2 strong,
  .th-lmk h3 strong,
  .th-lmk strong h1,
  .th-lmk strong h2,
  .th-lmk strong h3 {
    @apply font-serif font-semibold;
  }

  /* prettier-ignore */
  .th-gl {
    --primary: 218, 56, 35;             /* #da3823 */
    --secondary: 0, 133, 53;            /* #008535 */
    --black: 29, 28, 26;                /* #1d1c1a */
    --white: 255, 255, 255;             /* #ffffff */
    --grey1: 137, 137, 137;             /* #898989 */
    --grey2: 233, 229, 226;             /* #e9e5e2 */
    --grey3: 244, 244, 241;             /* #f4f4f1 */
    --background: 250, 250, 249;        /* #fafaf9 */
    --discount: 255, 84, 67;            /* #ff5443 */
    --information: 0, 133, 53;          /* #008535 */
    --error: 217, 82, 64;               /* #d95240 */
    --highlight: 255, 203, 46;          /* #ffcb2e */
    --hover: 222, 76, 57;               /* #de4c39 */
    --disabled: 240, 175, 167;          /* #f0afa7 */
    --informationBG: 242, 249, 245;     /* #f2f9f5 */
    --errorBG: 253, 245, 244;           /* #fdf5f4 */
    --highlightBG: 255, 252, 245;       /* #fffcf5 */
    --font: "Brown";
  }

  /* GL overrides */
  .th-gl {
    @apply font-normal;
    @apply font-sans;
  }

  .th-gl strong {
    @apply font-bold;
  }

  /* prettier-ignore */
  .th-rn {
    --primary: 255, 98, 72;             /* #ff6248 */
    --secondary: 131, 164, 77;          /* #83a44d */
    --black: 29, 28, 26;                /* #1d1c1a*/
    --white: 255, 255, 255;             /* #ffffff */
    --grey1: 137, 137, 137;             /* #898989 */
    --grey2: 233, 229, 226;             /* #e9e5e2 */
    --grey3: 244, 244, 241;             /* #f4f4f1 */
    --background: 250, 250, 249;        /* #fafaf9 */
    --discount: 255, 84, 67;            /* #ff5443 */
    --information: 131, 164, 77;        /* #83a44d */
    --error: 218, 56, 35;               /* #da3823 */
    --highlight: 255, 203, 46;          /* #ffCb2e */
    --hover: 255, 61, 29;               /* #ff3d1d */
    --disabled: 255, 192, 182;          /* #ffc0b6 */
    --informationBG: 249, 250, 246;     /* #f9faf6 */
    --errorBG: 253, 245, 244;           /* #fdf5f4 */
    --highlightBG: 255, 252, 245;       /* #fffcf5 */
    --font: "Museo Sans Rounded";
  }

  /* RN overrides */
  .th-rn {
    @apply font-light;
    @apply font-sans;
  }

  .th-rn strong {
    @apply font-medium;
  }

  .th-rn button {
    @apply font-light;
  }

  /* prettier-ignore */
  .th-chef {
      --primary: 229, 105, 98;            /* #e56962 */
      --secondary: 57, 65, 232;           /* #3941e8 */
      --black: 29, 28, 26;                /* #1d1c1a */
      --white: 255, 255, 255;             /* #ffffff */
      --grey1: 137, 137, 137;             /* #898989 */
      --grey2: 233, 229, 226;             /* #e9e5e2 */
      --grey3: 244, 244, 241;             /* #f4f4f1 */
      --background: 245, 243, 225;        /* #f5f3e1 */
      --error: 217, 82, 64;               /* #d95240 */
      --highlight: 255, 203, 46;          /* #ffcb2e */
    }
}

@layer components {
  /* Headings */
  h1 {
    @apply text-2xl md:text-4xl;
  }

  h2 {
    @apply text-xl md:text-3xl;
  }

  h3 {
    @apply text-lg;
  }

  /* Circle */
  .circle {
    display: inline-block;

    width: 1em;
    height: 1em;
    border-radius: 50%;

    content: "";

    background-color: currentColor;
  }

  /* Triangle */
  .triangle {
    display: flex;
  }

  .triangle::before {
    content: "";

    -webkit-clip-path: polygon(50% 10%, 0 100%, 100% 100%);
    clip-path: polygon(50% 10%, 0 100%, 100% 100%);

    background-color: inherit;
    @apply absolute left-0 -top-4 right-0 bottom-0 w-5 h-5 mx-auto rounded;
  }

  .triangle-left {
    display: flex;
  }
  .triangle-left::before {
    content: "";

    -webkit-clip-path: polygon(100% 0%, 100% 100%, 0% 50%);
    clip-path: polygon(100% 0%, 100% 100%, 0% 50%);

    background-color: inherit;
    right: revert;

    @apply absolute -left-4 top-0 bottom-0 w-5 h-5 mx-auto my-auto rounded;
  }

  /* Nudge */
  .nudge {
    @apply bg-highlight rounded-full w-3.5 h-3.5;
  }

  .nudge-error {
    @apply bg-error rounded-full w-3.5 h-3.5;
  }

  .nudge::before {
    content: "";
    @apply absolute top-0.75 left-1.5 bg-black w-0.5 h-1.25;
  }

  .nudge::after {
    content: "";
    @apply absolute bottom-0.75 left-1.5 bg-black w-0.5 h-0.5;
  }

  .nudge-error::before {
    content: "";
    @apply absolute top-0.75 left-1.5 bg-white w-0.5 h-1.25;
  }

  .nudge-error::after {
    content: "";
    @apply absolute bottom-0.75 left-1.5 bg-white w-0.5 h-0.5;
  }
}

@layer utilities {
  .font-none {
    font-family: arial !important;
  }

  .no-ligatures {
    font-variant-ligatures: none;
  }

  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .stretch-r {
    @apply -mr-[calc((100vw-100%)/2)];
  }

  .stretch-l {
    @apply -ml-[calc((100vw-100%)/2)];
  }

  .stretch-x {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  .stretch-y {
    height: 100vh;
    margin-top: calc(50% - 50vh);
  }

  .disable-scroll {
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .hyphenate {
    hyphens: auto;
  }

  .focus-ring {
    @apply ring ring-highlight;
  }

  .focus-ring-black {
    @apply ring ring-black;
  }

  .page-padding {
    @apply px-4 md:px-8 xl:px-15;
  }

  .page-max-width {
    @apply max-w-full mx-auto md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl;
  }

  .page-grid {
    @apply grid grid-cols-4 gap-x-2 gap-y-6 md:grid-cols-8 md:gap-x-4 xl:grid-cols-12 xl:gap-x-6;
  }

  .list-check {
    list-style-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="0.75em" height="0.75em" viewBox="0 0 12 12" fill="none"><path d="M1 6.5L4.33333 10L11 3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/></svg>');
  }

  .list-cross {
    list-style-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="0.6em" height="0.6em" viewBox="0 0 12 12" fill="none"><path d="M1 1L11 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/><path d="M1 11L11 1" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/></svg>');
  }
}
